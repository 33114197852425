import React from "react"
import tw, { styled } from "twin.macro"
import { PrimaryButton as PrimaryButtonBase } from "./misc/Buttons.jsx"
import { SectionHeading } from "./misc/Headings.jsx"
import ResponsiveVideoEmbed from "./helpers/ResponsiveVideoEmbed.jsx"

const Container = tw.div`relative h-full`
const Title = tw(SectionHeading)`w-full text-center pt-10`
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-0 lg:mx-auto p-0 lg:p-16`

const TextColumn = tw.div` text-left lg:w-6/12 py-8 lg:px-8 lg:py-0`
const Description = styled.div`
  ${tw`text-left  md:text-base lg:text-lg font-medium leading-relaxed `}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10 border-b-2`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8 border-b-2`}
  }
  h3 {
    ${tw`text-base font-bold mt-6`}
  }
`

const StyledResponsiveVideoEmbed = styled(ResponsiveVideoEmbed)`
  padding-bottom: 56.25% !important;
  padding-top: 0px !important;
  ${tw`rounded`}
  iframe {
    ${tw`rounded bg-black shadow-xl`}
  }
`
const FormColumn = tw.div` text-left lg:w-6/12 p-8 bg-gray-100 rounded-4xl border-2`

const Form = tw.form`text-lg flex flex-col w-full mx-auto md:mx-0 `
const Checkbox = tw.input`font-medium `
const Input = tw.input`text-base my-2 first:mt-0 border-b-2 py-2 focus:outline-none font-medium transition duration-300 hocus:border-primary-500`
const Textarea = tw.textarea`text-base my-2 first:mt-0 border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-primary-500 h-48`
const PrivacyPolicy = tw.a`text-center mt-4 text-base`
const SubmitButton = tw(PrimaryButtonBase)`inline-block mt-4 text-lg`
const Tel = tw.a`text-center my-4 text-xl inline-block`
const Information = tw.div`text-center mb-8 text-base`

export default () => {
  const description = (
    <>
      AI導入、活用に関するお悩みを、
      <br />
      "無料"でJDLA正会員のRossoに相談いただけます。
      <br /> <br />
      ■例えば、こんな方へお勧めです。
      <br />
      <h3>
        ✔データはあるが、整理や分析が出来ていない...
        <br />
        ✔AIを導入してみた結果を手軽に試してみたい...
        <br />
        ✔どのような作業をAIに代替出来るのか相談してみたい...
      </h3>
      <br />
      その他、ご相談もお気軽にお問合せ下さい。
      <h3>
        【開催日時】 平日 9:30~18:30 のうち1時間程度
        <br />
        【参加費】 無料
        <br />
        【会場】 オンラインにて（会議ツールは貴社指定も可能です）
        <br />
        ※各社様1回限りとなります
      </h3>
      <br />
      ■Rossoのサービスもご紹介致します。
    </>
  )

  return (
    <Container>
      <Title>AI オンライン相談会</Title>
      <TwoColumn>
        <TextColumn>
          <Description>{description}</Description>
          <StyledResponsiveVideoEmbed
            url="https://www.youtube.com/embed/yEH9zD0Kxmw"
            background="transparent"
          />
        </TextColumn>
        <FormColumn>
          <Form
            name="consultai"
            action="/thank-you/"
            method="POST"
            netlify-honeypot="bot-field"
            data-netlify="true"
          >
            <Input type="hidden" name="form-name" value="consultai" />
            <Input type="hidden" name="bot-field" />
            <b>相談テーマ（※ 複数選択可）</b>
            <p>
              <Checkbox type="checkbox" name="Theme1" value="AI市場調査" />
              AI市場調査
              <br />
              <Checkbox type="checkbox" name="Theme2" value="AI導入" />
              AI導入
              <br />
              <Checkbox type="checkbox" name="Theme3" value="AI精度向上" />
              AI精度向上
              <br />
              <Checkbox
                type="checkbox"
                name="Theme4"
                value="データ学習コスト削減"
              />
              データ学習コスト削減
              <br />
              <Checkbox type="checkbox" name="Theme5" value="費用コスト削減" />
              費用コスト削減
              <br />
              <Checkbox type="checkbox" name="Theme6" value="性能診断" />
              性能診断
              <br />
              <Checkbox type="checkbox" name="Theme7" value="その他" />
              その他
              <br />
            </p>
            <Input
              type="text"
              name="subject"
              placeholder=" 会社名(必須)"
              required
            />
            <Input
              type="text"
              name="name"
              placeholder=" お名前(必須)"
              required
            />
            <Input
              type="email"
              name="email"
              placeholder=" 会社メールアドレス(必須)"
              required
            />
            <Input
              type="text"
              name="date"
              placeholder=" ご都合の良い時間や曜日"
            />
            <Textarea
              name="message"
              placeholder=" ご相談内容"
              maxlength="2000"
            />
            <PrivacyPolicy
              href="https://www.rosso-tokyo.co.jp/contact/consent/"
              target="_blank"
              rel="noopener noreferrer"
            >
              個人情報の取り扱いについて
            </PrivacyPolicy>
            <SubmitButton type="submit">
              個人情報の取り扱いに同意し送信
            </SubmitButton>
          </Form>
        </FormColumn>
      </TwoColumn>
      <Information>
        <Tel href="tel:0364279037">☎︎ 03-6427-9037</Tel>
        <div>(サクッとAI､サクッとクラウド担当宛)</div>
        <div>営業時間平⽇9:30~18:30 (⼟⽇祝休み)</div>
      </Information>
    </Container>
  )
}
